import { LoanApplication } from "../../../../domain/models";
import { LoanApplicationEntity } from "../entities";
import { FeeInfoMapper } from "./";

export default class LoanApplicationMapper {
  static toModel(entity: LoanApplicationEntity): LoanApplication {
    return new LoanApplication({
      amount: entity.amount,
      amountToPay: entity.amount_to_pay,
      days: entity.days,
      address: entity.address,
      chargedInterest: entity.charged_interest,
      annualEffectiveInterestRate: entity.annual_effective_interest_rate,
      fees: entity.fees.map(fee => FeeInfoMapper.toModel(fee)),
      chargedMaintenanceFee: entity.charged_maintenance_fee,
      chargedIvaFee: entity.charged_iva_fee,
      interestDescription: entity.interest_description,
      maintenanceFeeDescription: entity.maintenance_fee_description,
      ivaFeeDescription: entity.iva_fee_description
    });
  }
}