import { Email, Text, CheckboxInput } from "../../../infrastructure/inputs";
import { DateUtil, Environment, Money } from "../../../utils";
import { useConfig, useNewLoanReferencesForm, useSimulateLoan } from "../../hooks";
import Button from "../shared/Button";
import Input from "../shared/Input";
import Checkbox from "../shared/Checkbox";
import { BeatLoader } from "react-spinners";
import { LoanSimulation, LoanSimulationRequest } from "../../../domain/models";
import { useEffect } from "react";
import Tooltip from "../shared/Tooltip";

interface PreviewLoanProps {
  loading: boolean;
  acceptedOnlineSignature: boolean;
  loanSimulation?: LoanSimulation;
}

const PreviewLoan: React.FC<PreviewLoanProps> = ({ loading, acceptedOnlineSignature, loanSimulation }) => {
  if(loading || !loanSimulation) {
    return (
      <BeatLoader aria-label="Loading Spinner"/>
    )
  }

  return(
    <div className={`w-full bg-gray-100 rounded-lg h-fit flex flex-col p-6 text-xs`}>
      <div><strong>Días calendario solicitados:</strong> {loanSimulation.days}</div>
      <div><strong>Tasa efectiva anual (E.A):</strong> {(loanSimulation.interestRate * 100.0).toFixed(2)}%</div>
      <div><strong>Tasa efectiva mensual (E.M):</strong> {(loanSimulation.monthlyInterestRate * 100.0).toFixed(2)}%</div>
      <div><strong>Fecha proyectada de pago:</strong> {DateUtil.formatDate(loanSimulation.estimatedPaymentDate)}</div>
      <div className="border-t border-gray-300 my-4"></div>

      <div className="flex flex-col space-y-2">
        <div><strong>Monto solicitado:</strong> {Money.formatToCOP(loanSimulation.amount)}</div>
        <div><strong>Interés corriente:</strong> {Money.formatToCOP(loanSimulation.chargedInterest)} <Tooltip text={loanSimulation.interestDescription} /></div>
        <div><strong>Cuota de manejo:</strong> {Money.formatToCOP(loanSimulation.chargedMaintenanceFee)} <Tooltip text={loanSimulation.maintenanceFeeDescription} /></div>
        {
          loanSimulation.fees.map((fee, index) => {
            return (
              <div key={index}><strong>{fee.fee.name}:</strong> {Money.formatToCOP(fee.amount)} <Tooltip text={fee.fee.description} /></div>
            )
          })
        }
      </div>
      <div><strong>IVA:</strong> {Money.formatToCOP(loanSimulation.chargedIvaFee)} <Tooltip text={loanSimulation.ivaFeeDescription} /></div>
      <div className="border-t border-gray-300 my-4"></div>
      <div><strong>Total proyectado a pagar:</strong> {Money.formatToCOP(loanSimulation.amountToPay)}</div>
      <div className="border-t border-gray-300 my-4"></div>
    </div>
  )
}

interface NewLoanReferencesFormProps {
  className?: string;
  onPrevious?: () => void;
  handler: ReturnType<typeof useNewLoanReferencesForm>;
  buildSimulationPayload: () => LoanSimulationRequest;
}

const NewLoanReferencesForm: React.FC<NewLoanReferencesFormProps> = ({ className = '', onPrevious, handler, buildSimulationPayload }) => {
  const {
    personalReferenceName, setPersonalReferenceName,
    personalReferencePhone, setPersonalReferencePhone,
    personalReferenceEmail, setPersonalReferenceEmail,
    personalReferenceRelationship, setPersonalReferenceRelationship,
    familyReferenceName, setFamilyReferenceName,
    familyReferencePhone, setFamilyReferencePhone,
    familyReferenceEmail, setFamilyReferenceEmail,
    familyReferenceRelationship, setFamilyReferenceRelationship,
    acceptedTerms, setAcceptedTerms,
    submitted, submitForm,
    developmentAutoFill,
    acceptedOnlineSignature, setAcceptedOnlineSignature
  } = handler;
  const { loading, simulateLoan, loanSimulation } = useSimulateLoan();
  const { config } = useConfig();

  useEffect(() => {
    simulateLoan(buildSimulationPayload(), acceptedOnlineSignature.getBoolean());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={`${className} flex flex-wrap flex-col`}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}
    >
      <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-10 md:space-y-0">
        <div className="w-full md:w-2/3">
          <h2 className="font-bold text-xl">Referencia personal</h2>
          <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 w-full font-medium gap-5 mt-5">
            <Input
              placeholder="Nombre"
              value={personalReferenceName.value}
              errorMessage={submitted ? personalReferenceName.errorMessage() : null}
              onChange={(event) => { setPersonalReferenceName(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Teléfono de contacto"
              type="number"
              value={personalReferencePhone.value}
              errorMessage={submitted ? personalReferencePhone.errorMessage() : null}
              onChange={(event) => { setPersonalReferencePhone(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Email"
              type="email"
              value={personalReferenceEmail.value}
              errorMessage={submitted ? personalReferenceEmail.errorMessage() : null}
              onChange={(event) => { setPersonalReferenceEmail(Email.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Relación"
              value={personalReferenceRelationship.value}
              errorMessage={submitted ? personalReferenceRelationship.errorMessage() : null}
              onChange={(event) => { setPersonalReferenceRelationship(Text.dirty(event.target.value)); }}
            />
          </div>

          <h2 className="font-bold text-xl mt-10">Referencia familiar</h2>

          <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 w-full font-medium gap-5 mt-5">
            <Input
              placeholder="Nombre"
              value={familyReferenceName.value}
              errorMessage={submitted ? familyReferenceName.errorMessage() : null}
              onChange={(event) => { setFamilyReferenceName(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Teléfono de contacto"
              value={familyReferencePhone.value}
              errorMessage={submitted ? familyReferencePhone.errorMessage() : null}
              onChange={(event) => { setFamilyReferencePhone(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Email"
              value={familyReferenceEmail.value}
              errorMessage={submitted ? familyReferenceEmail.errorMessage() : null}
              onChange={(event) => { setFamilyReferenceEmail(Email.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Relación"
              value={familyReferenceRelationship.value}
              errorMessage={submitted ? familyReferenceRelationship.errorMessage() : null}
              onChange={(event) => { setFamilyReferenceRelationship(Text.dirty(event.target.value)); }}
            />
          </div>

          <div className="flex flex-col space-y-2 mt-10">
            <Checkbox
              value={acceptedTerms.getBoolean()}
              errorMessage={submitted ? acceptedTerms.errorMessage() : null}
              onChange={(value) => { setAcceptedTerms(CheckboxInput.dirty(String(value))); }}
            >
              <label className="text-start opacity-65">Acepto los <a href={Environment.termsUrl} target='_blank' rel='noreferrer' className="text-sky-500 hover:text-sky-700">términos y condiciones</a></label>
            </Checkbox>

            <Checkbox
              disabled={loading}
              value={acceptedOnlineSignature.getBoolean()}
              errorMessage={submitted ? acceptedOnlineSignature.errorMessage() : null}
              onChange={(value) => {
                const newValue = CheckboxInput.dirtyAllowEmpty(String(value));
                setAcceptedOnlineSignature(newValue);
                simulateLoan(buildSimulationPayload(), newValue.getBoolean());
              }}
            >
              <label className="text-start opacity-65">Deseo usar firma electrónica para aceptar el préstamo una vez aprobado.</label>
            </Checkbox>
          </div>
        </div>

        <div className="flex w-full md:w-1/3 justify-center items-center">
          <PreviewLoan acceptedOnlineSignature={acceptedOnlineSignature.getBoolean()} loading={loading} loanSimulation={loanSimulation} />
        </div>
      </div>

      {
        Environment.env === 'development' && (
          <button className="absolute right-20" type="button" onClick={() => {
            developmentAutoFill();
          }}>Autofill</button>
        )
      }

      <div className="mt-5 bg-yellow-100 p-5 text-sm rounded-lg">
        Si has elegido usar firma electrónica, completarás el proceso en línea de forma rápida y segura una vez tu préstamo es aprobado. Ten en cuenta que este servicio tiene un costo asociado. Si prefieres no incurrir en este costo, puedes optar por la firma física, lo cual implica firmar el contrato, autenticarlo en una notaría y entregarlo en nuestras oficinas en <strong>{config?.contactAddress}</strong>. Para más información, no dudes en contactarnos a través de nuestros canales de atención.
      </div>

      <div className="flex justify-between w-full mt-10">
        <Button loading={false} disabled={false} className="w-auto" text="Anterior" onClick={onPrevious} />
        <Button loading={loading} disabled={false} className="w-auto" text="Aplicar" type="submit" />
      </div>
    </form>
  )
}

export default NewLoanReferencesForm;