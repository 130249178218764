import { FeeInfo } from "./";

interface ILoanSimulation {
  amount: number;
  days: number;
  interestRate: number;
  estimatedPaymentDate: Date;
  chargedInterest: number;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
  monthlyInterestRate: number;
  fees: FeeInfo[];
  amountToPay: number;
  interestDescription: string;
  maintenanceFeeDescription: string;
  ivaFeeDescription: string;
}

export default class LoanSimulation {
  amount: number;
  days: number;
  interestRate: number;
  monthlyInterestRate: number;
  estimatedPaymentDate: Date;
  chargedInterest: number;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
  fees: FeeInfo[];
  amountToPay: number;
  interestDescription: string;
  maintenanceFeeDescription: string;
  ivaFeeDescription: string;

  constructor({
    amount,
    days,
    interestRate,
    monthlyInterestRate,
    estimatedPaymentDate,
    chargedInterest,
    chargedMaintenanceFee,
    chargedIvaFee,
    fees,
    amountToPay,
    interestDescription,
    maintenanceFeeDescription,
    ivaFeeDescription
  }: ILoanSimulation) {
    this.amount = amount;
    this.days = days;
    this.interestRate = interestRate;
    this.monthlyInterestRate = monthlyInterestRate;
    this.estimatedPaymentDate = estimatedPaymentDate;
    this.chargedInterest = chargedInterest;
    this.chargedMaintenanceFee = chargedMaintenanceFee;
    this.chargedIvaFee = chargedIvaFee;
    this.fees = fees;
    this.amountToPay = amountToPay;
    this.interestDescription = interestDescription;
    this.maintenanceFeeDescription = maintenanceFeeDescription;
    this.ivaFeeDescription = ivaFeeDescription;
  }
}