import { Loan } from "../../../../domain/models";
import { LoanStatus, PaymentMethod } from "../../../../domain/models/loan";
import { LoanEntity } from "../entities";
import { FeeInfoMapper, LoanApplicationMapper, OnlinePaymentInfoMapper, ProviderContractMapper } from "./";

export default class LoanMapper {
  static toModel(entity: LoanEntity): Loan {
    return new Loan({
      id: entity.id,
      createdAt: entity.created_at,
      status: this.statusCdToLoanStatus(entity.status_cd),
      paymentUrl: entity.payment_url,
      amountApproved: entity.amount_approved,
      approvedConsecutive: entity.approved_consecutive,
      amountToPay: entity.amount_to_pay,
      chargedInterest: entity.charged_interest,
      annualEffectiveInterestRate: entity.annual_effective_interest_rate,
      fees: entity.fees.map(fee => FeeInfoMapper.toModel(fee)),
      consecutive: entity.consecutive,
      estimatedPaymentDate: entity.estimated_payment_date,
      paidAt: entity.paid_at,
      loanApplication: LoanApplicationMapper.toModel(entity.loan_application),
      depositedAt: entity.deposited_at,
      amountPaid: entity.amount_paid,
      negotiatedAmountToPay: entity.negotiated_amount_to_pay,
      negotiatedPaymentDate: entity.negotiated_payment_date,
      onlinePaymentInfo: entity.online_payment_info ? OnlinePaymentInfoMapper.toModel(entity.online_payment_info) : undefined,
      paymentMethod: (entity.payment_method_cd !== undefined && entity.payment_method_cd !== null) ? this.paymentMethodCdToEnum(entity.payment_method_cd) : undefined,
      acceptedOnlineSignature: entity.accepted_online_signature,
      signedContract: entity.signed_contract,
      providerContract: entity.provider_contract ? ProviderContractMapper.toModel(entity.provider_contract) : undefined,
      chargedMaintenanceFee: entity.charged_maintenance_fee,
      chargedIvaFee: entity.charged_iva_fee,
      interestDescription: entity.interest_description,
      maintenanceFeeDescription: entity.maintenance_fee_description,
      ivaFeeDescription: entity.iva_fee_description
    });
  }

  static statusCdToLoanStatus = (statusCd: number): LoanStatus => {
    switch (statusCd) {
      case 0:
        return LoanStatus.open;
      case 1:
        return LoanStatus.approved;
      case 3:
        return LoanStatus.deposited;
      case 4:
        return LoanStatus.paid;
      case 5:
        return LoanStatus.rejected;
      default:
        throw new Error(`Unknown status code: ${statusCd}`);
    }
  }

  static paymentMethodCdToEnum = (paymentMethodCd: number): PaymentMethod => {
    switch (paymentMethodCd) {
      case 0:
        return PaymentMethod.transfer;
      case 1:
        return PaymentMethod.online;
      default:
        throw new Error(`Unknown payment method: ${paymentMethodCd}`);
    }
  }
}