import { LoanSimulation } from "../../../../domain/models";
import { LoanSimulationEntity } from "../entities";
import { FeeInfoMapper } from "./";

export default class LoanSimulationMapper {
  static toModel(entity: LoanSimulationEntity): LoanSimulation {
    return new LoanSimulation({
      amount: entity.amount,
      days: entity.days,
      interestRate: entity.interest_rate,
      monthlyInterestRate: entity.monthly_interest_rate,
      estimatedPaymentDate: entity.estimated_payment_date,
      chargedInterest: entity.charged_interest,
      chargedMaintenanceFee: entity.charged_maintenance_fee,
      chargedIvaFee: entity.charged_iva_fee,
      fees: entity.fees.map(fee => FeeInfoMapper.toModel(fee)),
      amountToPay: entity.amount_to_pay,
      interestDescription: entity.interest_description,
      maintenanceFeeDescription: entity.maintenance_fee_description,
      ivaFeeDescription: entity.iva_fee_description
    });
  }
}