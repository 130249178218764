import { FeeInfo } from "./";

interface ILoanApplication {
  amount: number;
  amountToPay: number;
  days: number;
  address: string;
  annualEffectiveInterestRate: number;
  chargedInterest: number;
  chargedMaintenanceFee: number;
  fees?: FeeInfo[];
  interestDescription: string;
  maintenanceFeeDescription: string;
}

export default class LoanApplication {
  amount: number;
  amountToPay: number;
  days: number;
  address: string;
  annualEffectiveInterestRate: number;
  chargedInterest: number;
  chargedMaintenanceFee: number;
  fees: FeeInfo[];
  interestDescription: string;
  maintenanceFeeDescription: string;

  constructor({
    amount, amountToPay, days, address,
    annualEffectiveInterestRate, chargedInterest, fees = [],
    chargedMaintenanceFee,
    interestDescription,
    maintenanceFeeDescription
  }: ILoanApplication) {
    this.amount = amount;
    this.amountToPay = amountToPay;
    this.days = days;
    this.address = address;
    this.annualEffectiveInterestRate = annualEffectiveInterestRate;
    this.chargedInterest = chargedInterest;
    this.fees = fees;
    this.chargedMaintenanceFee = chargedMaintenanceFee;
    this.interestDescription = interestDescription;
    this.maintenanceFeeDescription = maintenanceFeeDescription;
  }
}